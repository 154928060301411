const RANK_ITEM_MUTATION = gql`
  mutation RankLearnerItemsMutation($input: UpdateRanksInput!) {
    rankLearnerItems(input: $input) {
      id
      rank
    }
  }
`

export default RANK_ITEM_MUTATION
